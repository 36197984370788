.slider {
margin-left: 2%;


.imageGrid {
    display: flex;
    flex-wrap: nowrap;
    
  
    div {
        background-color: #e9dcdc;
        box-shadow: 2px 2px 10px 1px rgb(196, 192, 192);
        border-radius: 5px;
        width: 20%;
        height: auto;
        margin: 10px;
        text-align: center;
    }
}
}
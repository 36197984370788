.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
  background-color: #eaecff;
  box-shadow: 4px 0px 10px 0px #cacaca;

  .logoutButton{
    background-color: #EAECFF;
    border: none;
    border-bottom: 1px solid gray;
  }
  .top {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      font-size: 28px;
      font-weight: bold;
      color: #6439ff;
      
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    padding-left: 20px;
    

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 14px;
        font-weight: bold;
        color: rgb(99, 99, 99);
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #fde3f7;
        }

        .icon {
          font-size: 25px;
          color: #7451f8;
          box-shadow: 4px 0px 10px 0px #EAECFF;
        }

        span{
            font-size: 17px;
            font-weight: 600;
            color: rgb(83, 83, 83);
            margin-left: 10px;
        }
      }
    }
  }

  .bottom{
      display: flex;
      align-items: center;
      margin: 10px;

      .colorOption{
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #7451f8;
          cursor: pointer;
          margin: 5px;

          &:nth-child(1){
              background-color: whitesmoke;
          }
          &:nth-child(2){
              background-color: #333;
          }
          &:nth-child(3){
              background-color: darkblue;
          }
      }
  }
}
